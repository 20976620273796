<template lang="pug">
main.home
  .opening(ref="loading")
    .loader

  .alert(:class="{'is-active': isAlert}")
    .texture-overlay.-alert
    | こちらのコンテンツは縦画面でお楽しみください。

  .texture-overlay

  .road(ref="road" @wheel="Wheel")
    .indicator(ref="indicator")
      .indicator__body(ref="indicatorBody")
      .indicator__main(ref="indicatorMain")
      .indicator__section.section(ref="indicatorSection" @click="Anchor(id)" v-for="(name, id) in sectionList")
        a.section__link {{ name }}
    nav.menu(:class="{'is-active': isMenu}")
      .menu__wrap
        ul.menu__sns.sns
          li.sns__item
            a.sns__link(href="https://twitter.com/blueperiod_ten/" target="_blank")
              img(src="/content/files/assets/img/menu_sns_twitter.svg", alt="twitter")
          li.sns__item
            a.sns__link(href="https://www.instagram.com/blueperiod_ten/" target="_blank")
              img(src="/content/files/assets/img/menu_sns_instagram.svg", alt="instagram")
          li.sns__item
            a.sns__link(href="https://www.tiktok.com/@blueperiodten" target="_blank")
              img(src="/content/files/assets/img/menu_sns_tiktok.svg", alt="tiktok")
          li.sns__item
            a.sns__link(href="https://www.youtube.com/channel/UCeMd_O0BffMFS0SCDWlnQrw" target="_blank")
              img(src="/content/files/assets/img/menu_sns_youtube.svg", alt="youtube")
          li.sns__item
            a.sns__link(href="https://www.instagram.com/accounts/login/?next=/ryuji_ayukawa/" target="_blank")
              img(src="/content/files/assets/img/menu_sns_heart.svg", alt="heart")
        ul.menu__list.list
          li.list__item(ref="menuListItem" v-for="(name, id) in menuList")
            a.list__link(@click="Anchor(id)") {{ name }}
        ul.menu__out.out
          li.out__item
            a.out__link(href="https://blue-period.jp/" target="_blank")
              | アニメ「ブルーピリオド」
          li.out__item
            a.out__link(href="https://blueperiod-stage.com/" target="_blank")
              | 銀河劇場プロデュース
              p 『ブルーピリオド』The Stage
          li.out__item
            a.out__link(href="https://afternoon.kodansha.co.jp/c/blueperiod.html" target="_blank")
              | ブルーピリオド｜アフタヌーン
        ul.menu__info.info
          li.info__item
            a.info__link(href="https://www.kodansha.co.jp/privacy/index.html" target="_blank")
              | プライバシーポリシー
          li.info__item
            | Ⓒ山口つばさ／講談社／ブルーピリオド展製作委員会

    button.menu__btn.btn(type="button" :class="{'is-active': isMenu}" @click="Menu")
      picture
        source(media="(max-width: 768px)" srcset="/content/files/assets/img/menu_btn_open_sp.svg")
        img.btn__open(src="/content/files/assets/img/menu_btn_open.svg", width="43" height="43", alt="MENU")
      img.btn__close(src="/content/files/assets/img/menu_btn_close.svg", width="43" height="43", alt="CLOSE")

    .closeModal(:class="{'is-active': isClose}")
      .closeModal__overlay(@click="CloseModal")
      .closeModal__content
        img.closeModal__close(@click="CloseModal" src="/content/files/assets/img/close_icon.svg", alt="閉じる"  width="33" height="33")
        .closeModal__autographs
          img(src="/content/files/assets/img/modal_img_240209.png", alt="" width="332" height="466")
        .closeModal__message
          h2.closeModal__ttl
            | 大阪会場が開催決定！
          p.closeModal__link
            a(href="https://www.ktv.jp/event/blueperiod/" target="_blank")
              | 詳しくはこちら
          p.closeModal__text
            span.-t1 2024年4月12日（金）～6月30日（日）
            span.-t2 金土日祝および
            span.-t3 4月30日（火）〜5月2日（木）開催
            span.-t4 大阪南港ATCギャラリー
            span.-t5 主催：関西テレビ放送、産経新聞社、ATC
            span.-t6 （東京展は終了しました。）

    .modal(:class="{'is-active': isModal}")
      .modal__overlay(@click="Modal")
      .modal__wrap
        .texture
        .modal__close(@click="Modal")
        .modal__contents
          .modal__block
            Slider_Modal.modal__slider(v-if="sliderImagesModal.length > 0"
              :id="'slider-modal'",
              :contents="sliderImagesModal",
              :interval="3000",
              :duration="300",
              :easing="'ease-out'",
              :controller="false",
              :pagination="true"
            )
          .modal__block
            h3.modal__heading.heading
              span.heading__main(v-html="modalHeadingMain")
              span.heading__sub(v-html="modalHeadingSub")
            .modal__text(v-html="modalText")
    .qa(:class="{'is-active': isQA}")
      .qa__overlay(@click="QA")
      .qa__wrap
        .qa__cover
          img.qa__close(@click="QA" src="/content/files/assets/img/close_icon.svg", alt="")
        .qa__image
          picture
            source(srcset="/content/files/assets/img/qa_sp.svg" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/qa.svg" media="(min-width: 769px)")
            img(src="/content/files/assets/img/qa.svg", alt="Q&A")
    .guideModal(:class="{'is-active': isGuideModal}")
      .guideModal__overlay(@click="guideModal")
      .guideModal__wrap
        .guideModal__container
          .guideModal__cover
            img.guideModal__close(@click="guideModal" src="/content/files/assets/img/close_icon.svg", alt="")
          .amb
            .guideModal__header
              h3.guideModal__heading Ambassador
              p.guideModal__text
                | 本展アンバサダーの麒麟・川島明さんが音声ガイドに挑戦！
                br.pc
                | 鑑賞者の皆さんと一緒に、ブルーピリオドや美術の世界を巡ります。
            .guideModal__contents
              .guideModal__block.-image
                Youtube(:index="0" :open="isGuideModal")
              .guideModal__block.-text
                .guideModal__profile.profile
                  h4.profile__heading.heading
                    span.heading__main 川島 明
                    span.heading__sub かわしま・あきら
                  p.profile__text
                    | 1979年2月3日生。AB 型。京都府出身。NSC 大阪校20 期生。
                    br.pc
                    | 1999年、田村裕とお笑いコンビ・麒麟を結成。「M-1 グランプリ」決勝に5 回進出。
                    br.pc
                    | 2021年4月朝の情報バラエティ番組「ラヴィット！」(TBS)で自身初の帯番組MCに就任し、「100%アピールちゃん」(TBS系)、「サンデーPUSHスポーツ」(日本ﾃﾚﾋﾞ系)などでもMCとして活躍中。その他TV、ラジオ、CMなどに多数出演中。
                .guideModal__comment.comment
                  h4.comment__heading Comment
                  p.comment__text
                    | 愛してやまない『ブルーピリオド』がこの度展覧会を開催するとのことでファンの一人として早くも興奮しております。
                    br.pc
                    | マンガともアニメとも違う「生」の作品に触れることで、観た人全員が必ずや何か生み出したくなる衝動に駆られることでしょう。
                    br.pc
                    | 『ブルーピリオド』の素晴らしさを共に身体で浴び、そして皆さんでさらにその奥に潜り込めることをアンバサダーとして楽しみにしております。
          .chara
            .guideModal__header
              h3.guideModal__heading Characters
              p.guideModal__text
                | 「キャラクターたちの対話で巡る音声ガイド
                br.pc
                | 八虎・世田介・橋田・森先輩と一緒に展覧会を楽しもう！」
            .guideModal__contents.-chara
              ul.guideModal__list.list
                li.list__item
                  .list__block.-image
                    Youtube(:index="1" :open="isGuideModal")
                    img(src="/content/files/assets/img/guide-modal_list_chara_img_01.png", alt="矢口八虎 峯田大夢 （スターダストプロモーション所属）")
                  .list__block.-text
                    .list__profile.profile
                      h4.profile__heading 【主な出演作】
                      p.profile__product
                        | 『セスタス -The Roman Fighter-』セスタス役
                        br
                        | 『フットサルボーイズ!!!!!』樫良木ルイ役など
                      p.profile__text
                        | ブルーピリオドの世界を追体験できる、わくわくと胸を打つ展示の数々を僕も目にするのが楽しみです！！本当にたくさんの人が関わっているこの展示に八虎として声で参加でき心から嬉しく思います！この展示を通してアートに、そしてブルーピリオドの世界に深く深く潜り込んでください。
                li.list__item
                  .list__block.-image
                    Youtube(:index="2" :open="isGuideModal")
                    img(src="/content/files/assets/img/guide-modal_list_chara_img_02.png", alt="高橋世田介 山下大輝 （アーツビジョン所属）")
                  .list__block.-text
                    .list__profile.profile
                      h4.profile__heading 【主な出演作】
                      p.profile__product
                        | 『僕のヒーローアカデミア』緑谷出久役
                        br
                        | 『ダンス・ダンス・ダンスール』村尾潤平役など
                      p.profile__text
                        | ブルーピリオドの世界をこれでもかと感じることができる贅沢な空間だなと思いました。様々なアート作品を、より深く、より広く自分の目で感じることができるようにしてある、ブルーピリオドでしかできない楽しい仕掛けが満載です。僕は久しぶりに世田介を演じられて幸せでした。皆さんも彼らと一緒に美術館に遊びにきているような気分で楽しんでいってください。
                li.list__item
                  .list__block.-image
                    Youtube(:index="3" :open="isGuideModal")
                    img(src="/content/files/assets/img/guide-modal_list_chara_img_03.png", alt="橋田悠 河西健吾 （マウスプロモーション所属）")
                  .list__block.-text
                    .list__profile.profile
                      h4.profile__heading 【主な出演作】
                      p.profile__product
                        | 「鬼滅の刃」時透無一郎役
                        br
                        | 「Dr.STONE」あさぎりゲン役 など
                      p.profile__text
                        | アニメの収録、放送が終わってからもブルーピリオドに関わらせていただけて本当に嬉しく思います。今回のブルーピリオド展のガイド案内の役割をやらせていただき、久しぶりのメンバーとの収録は凄く楽しかったです。僕個人もまだまだアートの世界はわからない事だらけなので一緒に楽しんで貰えたら幸いです。原作も併せてブルーピリオド展をどうぞお楽しみください。
                li.list__item
                  .list__block.-image
                    Youtube(:index="4" :open="isGuideModal")
                    img(src="/content/files/assets/img/guide-modal_list_chara_img_04.png", alt="森まる 青耶木まゆ （フクダ＆Co.所属）")
                  .list__block.-text
                    .list__profile.profile
                      h4.profile__heading 【主な出演作】
                      p.profile__product
                        | 「イーハー！きょうりゅうぼくじょう」ミン役
                        br
                        | 「ユアマジェスティ」ゴエティア役など
                      p.profile__text
                        | 作中に描かれた作品を、実際に等身大で鑑賞することができる展覧会と聞き、とても感動したと同時に頭の中で思い描いていたことが現実になったような感覚です。私も森まるとして展覧会場を見て回れたことはとても嬉しかったです。ご来場のお客様にも、キャラクターたちと共に鑑賞しているような感覚で、楽しんでいただけましたら幸いです。


    .truck(ref="truck")
      .banner(ref="banner")
        .banner__wrap
          a(href="http://w.pia.jp/p/blueperiod-ten22/" target="_blank")
            img(src="/content/files/assets/img/banner.svg", width="237" height="68", alt="チケット購入")

      section#top.top(ref="top")
        .texture
        .top__hero.hero
          h1.heading
            picture
              source(:srcset="'/content/files/assets/img/top_hero_img_'+openingImageNum+'_sp.png'" media="(max-width: 768px)")
              source(:srcset="'/content/files/assets/img/top_hero_img_'+openingImageNum+'.png'" media="(min-width: 769px)")
              img(ref="hero" :src="'/content/files/assets/img/top_hero_img_'+openingImageNum+'.png'", alt="ブルーピリオド展 アートって才能か？")
          h2.top__heading
            img(src="/content/files/assets/img/top_hero_heading.png", width="92" height="131", alt="route")
          div.hero__parts.parts
            div.parts__shadow
              img(src="/content/files/assets/img/top_parts_shadow.png", alt="影")
          img.hero__chara.chara.js-parallax(src="/content/files/assets/img/top_hero_chara_01.png", width="366" height="421", alt="")
        .top__read.read(ref="topRead")
          .read__text
            | 絵が上手いことは、才能か。
            br
            | 藝大に行くひとは、天才か。
            br
            | いや、毎日必死で、手と頭を動かし続けた結果だ。
            br
            | 評価される絵には、法則がある。
            br
            | 受かる絵にも、基準がある。
            br
            | でも、法則だけが、基準だけが、美術の全てではない。
            br
            br
            | 漫画『ブルーピリオド』は、
            br
            | そんな答えのない美術の世界にのめり込んだ
            br
            | 高校生の八虎とその仲間たちの物語。
            br
            br
            | 作中絵画や没入型シアターなど、
            br
            | 藝大を目指す道のりを追体験する展示から、
            br
            | 名画解説などのアートを
            br
            | 身近に感じることができる展示まで、
            br
            | さまざまな企画を通して
            br
            | ブルーピリオドの世界に迫っていく。
            br
            br
            | 新しいブルーピリオドとの出会い、
            br
            | そして、新しいアートとの出会いが、今はじまる。
          .read__movie.movie
            Slider_Main.movie__slider(
              :id="'slider-main'",
              :contents="sliderImagesMain",
              :interval="3000",
              :duration="300",
              :easing="'ease-out'",
              :controller="false",
              :pagination="false"
            )
            .movie__frame
              picture
                img(src="/content/files/assets/img/top_movie_frame.png", width="549" height="316", alt="枠")
            .movie__shadow
              picture
                img(src="/content/files/assets/img/top_movie_shadow.png", width="549" height="337", alt="影")
            .movie__plumbing
              img(src="/content/files/assets/img/top_movie_plumbing.png", width="10" height="358", alt="配管")

          .read__mess.mess
            .mess__board
              img(src="/content/files/assets/img/top_mess_board.png", width="256" height="348", alt="メッセージボード")
            .mess__detail.detail
              .detail__sns.sns
                a.sns__link(href="https://twitter.com/28_3" target="_blank")
                  img(src="/content/files/assets/img/top_mess_detail_sns_qr.svg", width="42" height="42", alt="ツイッターqrコード")
                  img(src="/content/files/assets/img/top_mess_detail_sns_id.svg", width="30" height="6", alt="ツイッターid")
              .detail__warning
                img(src="/content/files/assets/img/top_mess_detail_warning_camera.svg", width="35" height="35", alt="写真ok")
                img(src="/content/files/assets/img/top_mess_detail_warning_hand.svg", width="35" height="35", alt="触れるの禁止")

      section#news.news(ref="news")
        h2.news__heading.heading
          picture
            source(srcset="/content/files/assets/img/news_heading_sp.png" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/news_heading.png" media="(min-width: 769px)")
            img.heading__main(src="/content/files/assets/img/news_heading.png", width="207" height="190", alt="01")
          img.heading__sub(src="/content/files/assets/img/news_heading_notice.svg", width="136" height="71", alt="お知らせ")
          p.heading__read
            | 最新情報をお知らせします。
            br
            | 詳細は公式SNSをご覧ください。
          .heading__sns.sns
            a.sns__link(href="https://twitter.com/blueperiod_ten" target="_blank")
              img(src="/content/files/assets/img/news_sns_twitter.svg", alt="twitter")
            a.sns__link(href="https://www.instagram.com/blueperiod_ten/" target="_blank")
              img(src="/content/files/assets/img/news_sns_instagram.svg", alt="instagram")
            a.sns__link(href="https://www.tiktok.com/@blueperiodten" target="_blank")
              img(src="/content/files/assets/img/news_sns_tiktok.svg", alt="tiktok")
            a.sns__link(href="https://www.youtube.com/channel/UCeMd_O0BffMFS0SCDWlnQrw" target="_blank")
              img(src="/content/files/assets/img/news_sns_youtube.svg", alt="youtube")
        img.news__chara.chara.js-parallax(src="/content/files/assets/img/news_chara_01.png", width="338" height="400", alt="")
        .news__news.news
          .texture
          picture
            source(srcset="/content/files/assets/img/news_depth_sp.png" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/news_depth.png" media="(min-width: 769px)")
            img.news__depth(ref="newsDepth" src="/content/files/assets/img/news_depth.png", alt="奥行き")
          .news__board.board
            picture
              source(srcset="/content/files/assets/img/news_shadow_sp.svg" media="(max-width: 768px)")
              source(srcset="/content/files/assets/img/news_shadow.svg" media="(min-width: 769px)")
              img.board__shadow(src="/content/files/assets/img/news_shadow.svg", width="807" height="581", alt="news shadow")
            picture
              source(srcset="/content/files/assets/img/news_frame_sp.svg" media="(max-width: 768px)")
              source(srcset="/content/files/assets/img/news_frame.svg" media="(min-width: 769px)")
              img.board__frame(src="/content/files/assets/img/news_frame.svg", width="701" height="566", alt="news frame")
            .board__post.post
              .post__wrap
                article.post__article.article(v-for="data in json")
                  time.article__time {{ data.time }}
                  p.article__text(v-html="data.text")
              p.post__scroll
                | Scroll
            .twitter__wrap
              Timeline(sourceType="profile" :id="twitterId" :options="{'width': twitterWidth, 'height': twitterHeight}")

      section#hl.hl(ref="hl")
        .texture
        h2.hl__heading.heading
          picture
            source(srcset="/content/files/assets/img/hl_heading_sp.png" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/hl_heading.png" media="(min-width: 769px)")
            img.heading__main(src="/content/files/assets/img/hl_heading.png", width="239" height="190", alt="02")
          img.heading__sub(src="/content/files/assets/img/hl_heading_highlights.svg", width="267" height="81", alt="the highlights みどころ")
          p.heading__read
            | ブルーピリオド展の見どころを紹介。
            br
            | クリックして詳細をご覧ください。
        ul.hl__list.list
          li.list__item.item--01
            h3.list__heading
              img(src="/content/files/assets/img/hl_01_heading.svg", width="83" height="58", alt="")
            .list__image(
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_item-01_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_02.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_03.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_04.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_05.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_06.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_07.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-01_img_08.jpg", "alt": ""}]',
              data-heading-main="作中絵画",
              data-heading-sub="Paintings from<br>the Comic Book",
              data-text="<p>作中の随所に登場する、八虎や仲間たちが描いた作品の数々。その現物や再現絵画を展示します。</p>"
            )
              img.list__click(src="/content/files/assets/img/hl_click.svg", alt="")
              img(src="/content/files/assets/img/hl_01_img.png", width="227" height="280", alt="")
            img.list__chara.chara.js-parallax(src="/content/files/assets/img/hl_01_chara.png", width="252" height="354", alt="")
          li.list__item.item--02
            h3.list__heading
              img(src="/content/files/assets/img/hl_02_heading.svg", width="83" height="58", alt="")
            .list__image(
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_item-02_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-02_img_02.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-02_img_03.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-02_img_04.jpg", "alt": ""}]',
              data-heading-main="名画の見かた",
              data-heading-sub="How to Appreciate a Masterpiece",
              data-text="<p>八虎が出会った、数多くの名画。橋田が案内しながら、名画の背景や技法などを様々な方向から解説。新しい美術鑑賞体験をお届けします。</p>"
            )
              img.list__click(src="/content/files/assets/img/hl_click.svg", alt="")
              img(src="/content/files/assets/img/hl_02_img.png", width="253" height="303", alt="")
          li.list__item.item--03
            h3.list__heading
              img(src="/content/files/assets/img/hl_03_heading.svg", width="83" height="58", alt="")
          li.list__item.item--04
            h3.list__heading
              picture
                source(srcset="/content/files/assets/img/hl_04_heading_sp.svg" media="(max-width: 768px)")
                source(srcset="/content/files/assets/img/hl_04_heading.svg" media="(min-width: 769px)")
                img(src="/content/files/assets/img/hl_04_heading.svg", width="125" height="130", alt="")
            .list__depth.depth(
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_item-04_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-04_img_02.jpg", "alt": ""}]',
              data-heading-main="青の渋谷シアター",
              data-heading-sub="The Blue Shibuya Theater",
              data-text="<p>早朝の渋谷を「青い」と感じた八虎。八虎と一緒に青い渋谷に没入し、美術の道に進む過程を追体験できる巨大シアター。</p>"
            )
              img.depth__image(ref="hl04Depth" src="/content/files/assets/img/hl_04_depth_img.png", alt="奥行き")
            img.list__chara.chara.js-parallax(src="/content/files/assets/img/hl_04_chara.png", width="439" height="355", alt="")
          li.list__item.item--05
            h3.list__heading
              img(src="/content/files/assets/img/hl_05_heading.svg", width="83" height="58", alt="")
            .list__image(
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_item-05_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-05_img_02.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-05_img_03.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-05_img_04.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-05_img_05.jpg", "alt": ""}]',
              data-heading-main="キャラ大石膏室",
              data-heading-sub="Room with Plaster Characters",
              data-text="<p>美大入試の定番課題である石膏像。藝大にある大石膏室をイメージし、登場キャラクターをモチーフにした石膏像を複数展示。</p><br/><br class='pick'>このコーナーではデッサン体験ができます（平日のみ）。体験ご希望の方はお近くのスタッフにお声がけください。<br/>※詳しくはスペシャルをご覧ください。</br>※『キャラ大石膏室』に展示された像は全て、造形物を石膏像風に仕上げたものです。</p>"
            )
              img.list__click(src="/content/files/assets/img/hl_click.svg", alt="")
              img(src="/content/files/assets/img/hl_05_img.png", width="229" height="480", alt="")
            img.list__chara.chara.js-parallax(src="/content/files/assets/img/hl_05_chara.png", width="204" height="374", alt="")
          li.list__item.item--06
            h3.list__heading
              img(src="/content/files/assets/img/hl_06_heading.svg", width="83" height="58", alt="")
            .list__image(
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_item-06_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-06_img_04.png", "alt": ""}]',
              data-heading-main="一次試験",
              data-heading-sub="The First-stage Exam",
              data-text="<p>藝大の一次試験会場を再現。八虎が描いた自画像だけでなく、その他の受験生の作品も公開します。</p>"
            )
              img.list__click(src="/content/files/assets/img/hl_click.svg", alt="")
              img(src="/content/files/assets/img/hl_06_img.png", width="338" height="248", alt="")
          li.list__item.item--07
            h3.list__heading
              img(src="/content/files/assets/img/hl_07_heading.svg", width="83" height="58", alt="")
            .list__image(
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_item-07_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_item-07_img_02.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-07_img_03.jpg", "alt": ""},{"path": "/content/files/assets/img/modal_item-07_img_04.jpg", "alt": ""}]',
              data-heading-main="山口つばさの部屋",
              data-heading-sub="Tsubasa Yamaguchi’s Room",
              data-text="<p>漫画の作者・山口つばさ先生の過去と現在の作品や特別動画を展示。山口先生について多方面から深く知ることのできるコーナーです。</p>"
            )
              img.list__click(src="/content/files/assets/img/hl_click.svg", alt="")
              img(src="/content/files/assets/img/hl_07_img.png", width="233" height="239", alt="")
            img.list__chara.chara.js-parallax(src="/content/files/assets/img/hl_07_chara.png", width="245" height="411", alt="")
      section#special.special(ref="special")
        h2.special__wall
          img(class="pc" ref="specialWallPc" src="/content/files/assets/img/special_wall.svg", alt="03 Special スペシャル")
          img(class="sp" ref="specialWallSp" src="/content/files/assets/img/special_wall_sp.svg", alt="03 Special スペシャル")
        .special__wrap
          ul.special__list.list
            li.list__item
              a.list__link(href="https://pigment.tokyo/ja/workshop/" target="_blank")
                img(src="/content/files/assets/img/special_list_img_01.png")
            li.list__item
              img(src="/content/files/assets/img/special_list_img_02.png")
            li.list__item
              a.list__link(href="https://warehouseofart.org/blueperiod/" target="_blank")
                img(src="/content/files/assets/img/special_list_img_03.png")
            li.list__item
              a.list__link(href="https://www.scrapmagazine.com/news/blue-period0628/" target="_blank")
                img(src="/content/files/assets/img/special_list_img_04.png")
            li.list__item
              img(src="/content/files/assets/img/special_list_img_05.png")
            li.list__item
              img(src="/content/files/assets/img/special_list_img_06.png")
            li.list__item
              img(src="/content/files/assets/img/special_list_img_07.png")
            li.list__item
              img(src="/content/files/assets/img/special_list_img_08.png")
      section#ticket.ticket(ref="ticket")
        .texture
        img.ticket__chara.js-parallax(src="/content/files/assets/img/ticket_chara_01.png", width="299" height="476", alt="")
        h2.ticket__wall
          picture
            source(srcset="/content/files/assets/img/ticket_wall_sp.svg" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/ticket_wall.svg" media="(min-width: 769px)")
            img(ref="ticketWall" src="/content/files/assets/img/ticket_wall.svg", alt="Ticket チケット情報")
        .ticket__image
          img(usemap="#ticketImg" src="/content/files/assets/img/ticket_img.svg", alt="会場で楽しみたい方、オンラインで楽しみたい方、通常展とオンラインを両方楽しみたい方")
          map(name="ticketImg")
            area(title="qa" coords="612,415,656,454" shape="rect" @click="QA")
            area(title="corona" coords="656,440,698,481" shape="rect" href="/content/files/assets/img/corona.pdf" target="_blank")
            area(title="privilege" coords="756,19,995,488" shape="rect"
              @click="Modal",
              data-images='[{"path": "/content/files/assets/img/modal_ticket_img_01.png", "alt": ""},{"path": "/content/files/assets/img/modal_ticket_img_02.png", "alt": ""},{"path": "/content/files/assets/img/modal_ticket_img_03.png", "alt": ""},{"path": "/content/files/assets/img/modal_ticket_img_04.png", "alt": ""},{"path": "/content/files/assets/img/modal_ticket_img_05.png", "alt": ""},{"path": "/content/files/assets/img/modal_ticket_img_06.png", "alt": ""},{"path": "/content/files/assets/img/modal_ticket_img_07.png", "alt": ""}]',
              data-heading-main="来場者特典",
              data-heading-sub="Exclusive gifts for visitors",
              data-text="<p>ブルーピリオド展の来場者特典として、下書きやネームを使用したポストカードを全14種類ご用意しました。前期はキャラクター設定資料（7種類）から後期は一筆目ネーム原稿（7種類）から日替わりで1種をプレゼントいたします。</p>"
            )
      section#guide.guide(ref="guide")
        .texture
        h2.guide__wall(ref="guideWall")
          img(usemap="#guideWallSp" class="sp" src="/content/files/assets/img/guide_wall_sp.png", alt="Voice Guide 音声ガイド")
          map(name="guideWallSp" class="sp")
            area(title="amb" coords="225,428,725,963" shape="rect" data-id="amb" @click="guideModal")
            area(title="chara" coords="788,429,1289,964" shape="rect" data-id="chara" @click="guideModal")
          img(usemap="#guideWallPc" class="pc" src="/content/files/assets/img/guide_wall.png",alt="Voice Guide 音声ガイド")
          map(name="guideWallPc" class="pc")
            area(title="amb" coords="293,599,942,1294" shape="rect" data-id="amb" @click="guideModal")
            area(title="chara" coords="1029,594,1680,1294" shape="rect" data-id="chara" @click="guideModal")
      section#digital.digital(ref="digital")
        .texture
        h2.digital__wall
          picture
            source(srcset="/content/files/assets/img/digital_wall_sp.svg" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/digital_wall.svg" media="(min-width: 769px)")
            img(ref="digitalWall" src="/content/files/assets/img/digital_wall.svg", alt="06 Digital ブルーピリオド展デジタル")
        .read__movie.movie
            .movie__shadow
              picture
                img(src="/content/files/assets/img/digital_movie_shadow.png", width="549" height="337", alt="影")
            .movie__plumbing
              img(src="/content/files/assets/img/top_movie_plumbing.png", width="10" height="358", alt="配管")
            .movie__youtube
              Youtube(:index="5")
            .movie__frame
              picture
                img(src="/content/files/assets/img/top_movie_frame.png", width="549" height="316", alt="枠")
        p.digital__caption ダイジェスト映像をクリック！
      section#project.project(ref="project")
        .texture
        h2.project__wall
          picture
            source(srcset="/content/files/assets/img/project_wall_sp.svg" media="(max-width: 768px)")
            source(srcset="/content/files/assets/img/project_wall.svg" media="(min-width: 769px)")
            img(ref="projectWall" src="/content/files/assets/img/project_wall.svg" alt="07 Blue Art Project ブルーアートプロジェクト")
        .project__wrap
          .project__board
            picture
              source(srcset="/content/files/assets/img/project_board.svg" media="(max-width: 768px)")
              source(srcset="/content/files/assets/img/project_board.svg" media="(min-width: 769px)")
              img(usemap="#projectBoard" src="/content/files/assets/img/project_board.svg")
            map(name="projectBoard")
              area(shape="rect" coords="77,108,251,346"
                @click="Modal",
                data-images='[{"path": "/content/files/assets/img/modal_project_img_01.png", "alt": ""}]',
                data-heading-main="キービジュアル制作",
                data-heading-sub="About Key Visuals",
                data-text="<p>全国の美大受験予備校の生徒34名に自由な感性で単行本1〜6巻の表紙を描き下ろしていただき、それらの作品をキャラクターごとに再構成（コラージュ）することで、アートの多様性を感じさせる6種のキービジュアルが生み出されました。</p><br/><p>会場では、全34点のオリジナル作品も展示いたします。</p>"
              )
              area(shape="rect" coords="299,109,470,347"
                @click="Modal",
                data-images='[{"path": "/content/files/assets/img/modal_project_img_02.png", "alt": ""}]',
                data-heading-main="ブルーアートオーディション",
                data-heading-sub="Blue Art Audition",
                data-text="<p>アーティストデビューを目指す方のためにブルーピリオド展とTikTokが組んで、クリエイターオーディションを開催。審査で選ばれた優秀な作品を会場に展示いたします。</p><br/><a href='https://activity.tiktok.com/magic/eco/runtime/release/62a6a9436b22de033b28b824?appType=tiktok&iid=7109819913480570626&u_code=DJ69MI9HE3:FL7' target='_block'><img src='/content/files/assets/img/modal_project_link.svg'></a>"
              )
              area(shape="rect" coords="520,109,692,349"
                @click="Modal",
                data-images='[{"path": "/content/files/assets/img/modal_project_img_03.png", "alt": ""}]',
                data-heading-main="ブルーアートコラボレーション",
                data-heading-sub="Blue Art Collaboration",
                data-text="<p>現代アーティスト20名がブルーピリオドの作中課題に沿った作品を制作。それらの作品は前後期にわけて会場で展示、またアート・コミュニケーションプラットフォーム「ArtSticker」にて販売いたします。</p><br/><ul><li><h4>●作中課題</h4>３つのうちのいずれか、①私の好きな風景　②私の大事なもの　③自画像（本当の自分）</li><br/><li><h4>●参加アーティスト　50音順</h4>前期：6/18(土)〜8/5(金)、全8作家（新井碧、⼤澤巴瑠、岡田佑里奈、清川漠、小西葵、仲衿香、長谷川彩織、やんツー）</br> 後期：8/6(土)〜9/27(火)、全12作家（大西茅布、工藤時生、鮫島ゆい、杉田万智、杉本憲相、杉山日向子、suma、永田優美、フカミエリ、松田ハル、山ノ内陽介、ユゥキユキ）</li></ul><br/><a href='https://bit.ly/3wMyNq8' target='_block'><img src='/content/files/assets/img/modal_project_link.svg'></a>"
              )
      section#goods.goods(ref="goods")
        .texture
        img.goods__chara.chara.js-parallax(src="/content/files/assets/img/goods_chara_01.png" width="253" height="459")
        h2.goods__wall(ref="goodsWall")
          img(usemap="#goodsWallSp" class="sp" src="/content/files/assets/img/goods_wall_sp.svg", alt="08 Goods グッズ情報")
          img(usemap="#goodsWallPc" class="pc" src="/content/files/assets/img/goods_wall.svg", alt="08 Goods グッズ情報")
        a.goods__link(href="/content/files/assets/img/goods-list.pdf" target="_blank")
          img(src="/content/files/assets/img/goods_link.svg")
      section#summary.summary(ref="summary")
        .texture
        img.summary__shadow(src="/content/files/assets/img/summary_shadow.png", alt="")
        .summary__wrap
          h2.summary__heading
            img(src="/content/files/assets/img/summary_heading.svg", width="337" height="62" alt="ブルーピリオド展アートって才能か？")
          dl
            div.block.session
              div.td
                dt 会期：
                dd 2022年6月18日(土) ～ 9月27日(火)
              div.td.-small
                dt 前期：
                dd 6月18日(土) - 8月5日(金)  後期：8月6日(土) - 9月27日(火)
            div.block.time
              div.td
                dt 時間：
                dd 前期：10:00-20:00  後期：10:00-18:00
                  p.-small ※最終入場は閉館時間の30分前まで
            div.block.venue
              div.td
                dt 会場：
                dd 寺田倉庫G1ビル
                  span.-small 東京都品川区東品川2-6-4
            div.block.acs
              div.td
                dd
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.230901720342!2d139.74899091710537!3d35.622033802466866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b1839f22bb3%3A0x36254c767995025!2z5a-655Sw5YCJ5bqrRzHjg5Pjg6s!5e0!3m2!1sja!2sjp!4v1649597698788!5m2!1sja!2sjp" width="342" height="155" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  p.-small りんかい線 天王洲アイル駅 B出口より徒歩4分
                  p.-small 東京モノレール羽田空港線 天王洲アイル駅 中央口より徒歩5分
            div.block.-small
              div.td
                dt 主催：
                dd ブルーピリオド展製作委員会
            div.block.contact
              p.shadow
                span.-small よくある質問は
                  a.decorate(@click="QA") こちら
            div.block.sponsor.-small
              div.td
                dt 協賛：
                dd TikTok/寺田倉庫株式会社
              div.td
                dt 後援：
                dd 品川区
              div.td
                dt 協力：
                dd ArtSticker/新宿美術学院/SCRAP
            p.note ※今後の社会状況等により開催内容が変更になる場合があります。最新の開催情報は本サイトにてご確認ください。
      section#exit.exit
        .texture
        img.exit__wall(src="/content/files/assets/img/exit_wall.png", alt="壁")
        img.exit__board(src="/content/files/assets/img/exit_board.png", width="87" height="178" alt="exit")
</template>

<script>
import UA from "@/modules/userAgent";
import FADE from "@/modules/fade";
import Youtube from "@/components/youtube/main";
import Slider_Main from "@/components/slider/main";
import Slider_Modal from "@/components/slider/modal";
import NEWS from "/public/content/files/assets/news.json";
import { Timeline } from "vue-tweet-embed";
import imageMapResize from "image-map-resizer";

export default {
  components: {
    Slider_Main,
    Slider_Modal,
    Timeline,
    Youtube,
  },
  data() {
    return {
      sectionList: {
        top: "Top",
        news: "News",
        hl: "Highlights",
        special: "Special",
        ticket: "Ticket",
        guide: "Voice Guide",
        digital: "Digital",
        project: "Blue Art Project",
        goods: "Goods",
        summary: "About",
      },
      menuList: {
        news: "おしらせ",
        hl: "みどころ",
        special: "スペシャル",
        ticket: "チケット",
        guide: "音声ガイド",
        digital: "ブルーピリオド展デジタル",
        project: "ブルーアートプロジェクト",
        goods: "オリジナルグッズ",
        summary: "展示概要・アクセス",
      },
      sliderImagesMain: [
        { path: "/content/files/assets/img/slider_img_01.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_02.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_03.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_04.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_05.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_06.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_07.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_08.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_09.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_10.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_11.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_12.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_13.jpg", alt: "" },
        { path: "/content/files/assets/img/slider_img_14.jpg", alt: "" },
      ],
      sliderImagesDigital: [
        {
          path: "/content/files/assets/img/slider_digital_img_01.jpg",
          alt: "",
        },
        {
          path: "/content/files/assets/img/slider_digital_img_02.jpg",
          alt: "",
        },
        {
          path: "/content/files/assets/img/slider_digital_img_03.jpg",
          alt: "",
        },
        {
          path: "/content/files/assets/img/slider_digital_img_04.jpg",
          alt: "",
        },
      ],
      section: 10,
      sliderImagesModal: [],
      modalHeadingMain: "",
      modalHeadingSub: "",
      modalText: "",
      isLoad: true,
      isMenu: false,
      isClose: false,
      isModal: false,
      isGuideModal: false,
      isQA: false,
      isAlert: false,
      isStopScroll: false,
      json: NEWS,
      twitterId: "blueperiod_ten",
      direction: false,
      vertical: false,
      horizontal: false,
      verticalScroll: 0,
      horizontalScroll: 0,
      touchVertical: false,
      touchHorizontal: false,
      scrollValue: 0,
      transNum: 4,
    };
  },
  computed: {
    openingImageNum() {
      let min = 1;
      let max = 6;
      let rand = Math.floor(Math.random() * max) + min;
      let storageNum = sessionStorage.getItem("num") || 1;
      while (rand == storageNum) {
        rand = Math.floor(Math.random() * max) + min;
      }
      sessionStorage.setItem("num", rand);
      return rand;
    },
    twitterWidth() {
      return UA.IS_WIN ? 294 : 236;
    },
    twitterHeight() {
      return UA.IS_WIN ? 457 : 359;
    },
  },
  mounted() {
    const contentsH = 800;

    window.addEventListener("load", () => {
      // スマホの向き判定
      window.addEventListener("orientationchange", () => {
        switch (window.orientation) {
          case 0:
            this.isAlert = false;
            break;
          case -90:
            this.isAlert = true;
            break;
          case 90:
            this.isAlert = true;
            break;
          case 180:
            this.isAlert = false;
            break;
        }
      });

      // スマホ、safari height: 100%バグ対処
      this.$refs["road"].style.height = window.innerHeight + "px";
      if (window.innerHeight >= contentsH) {
        this.$refs["hero"].style.height = contentsH + "px";
        this.$refs["newsDepth"].style.height = contentsH + "px";
        this.$refs["hl04Depth"].style.height = contentsH - 44 + "px";
        this.$refs["specialWallPc"].style.height = contentsH + "px";
        this.$refs["specialWallSp"].style.height = contentsH + "px";
        this.$refs["ticketWall"].style.height = contentsH + "px";
        this.$refs["guideWall"].style.height = contentsH + "px";
        this.$refs["digitalWall"].style.height = contentsH + "px";
        this.$refs["projectWall"].style.height = contentsH + "px";
        this.$refs["goodsWall"].style.height = contentsH + "px";
      } else {
        this.$refs["hero"].style.height = window.innerHeight + "px";
        this.$refs["newsDepth"].style.height = window.innerHeight + "px";
        this.$refs["hl04Depth"].style.height = window.innerHeight - 44 + "px";
        this.$refs["specialWallPc"].style.height = window.innerHeight + "px";
        this.$refs["specialWallSp"].style.height = window.innerHeight + "px";
        this.$refs["ticketWall"].style.height = window.innerHeight + "px";
        this.$refs["guideWall"].style.height = window.innerHeight + "px";
        this.$refs["digitalWall"].style.height = window.innerHeight + "px";
        this.$refs["projectWall"].style.height = window.innerHeight + "px";
        this.$refs["goodsWall"].style.height = window.innerHeight + "px";
      }

      FADE(this.$refs.loading, "out");
      setTimeout(() => (this.isClose = true), 800);

      // コンテンツ全体の横幅取得
      const sections = document.querySelectorAll("section");
      let sectionsWidth = 0;
      for (let i = 0; i < sections.length; i++) {
        sectionsWidth += sections[i].clientWidth;
      }
      this.area = sectionsWidth - window.innerWidth;

      let index = 0;

      for (const key in this.sectionList) {
        const rectLeft =
          this.$refs[key].getBoundingClientRect().left -
          window.innerWidth / this.transNum;
        const value =
          (this.$refs["indicatorBody"].clientWidth / this.area) * rectLeft;
        const pos =
          value - this.$refs["indicatorSection"][index].clientWidth / 2;
        this.$refs["indicatorSection"][index].style.left = pos + "px";
        this.$refs["indicatorSection"][index].dataset.value = pos;
        if (index == 0) {
          this.$refs["indicatorSection"][index].classList.add("is-on");
          this.$refs["indicatorSection"][index].style.left =
            -(this.$refs["indicatorSection"][index].clientWidth / 2) + "px";
          this.$refs["indicatorSection"][index].dataset.value = 0;
        } else if (index == this.section - 1) {
          this.$refs["indicatorSection"][index].style.left = "auto";
          this.$refs["indicatorSection"][index].style.right =
            -(this.$refs["indicatorSection"][index].clientWidth / 2) + "px";
          this.$refs["indicatorSection"][index].dataset.value =
            this.$refs["indicatorBody"].clientWidth;
        }
        index++;
      }

      window.addEventListener("resize", () => {
        this.$refs["road"].style.height = window.innerHeight + "px";
        if (window.innerHeight >= contentsH) {
          this.$refs["hero"].style.height = contentsH + "px";
          this.$refs["newsDepth"].style.height = contentsH + "px";
          this.$refs["hl04Depth"].style.height = contentsH - 44 + "px";
          this.$refs["specialWallPc"].style.height = contentsH + "px";
          this.$refs["specialWallSp"].style.height = contentsH + "px";
          this.$refs["ticketWall"].style.height = contentsH + "px";
          this.$refs["guideWall"].style.height = contentsH + "px";
          this.$refs["digitalWall"].style.height = contentsH + "px";
          this.$refs["projectWall"].style.height = contentsH + "px";
          this.$refs["goodsWall"].style.height = contentsH + "px";
        } else {
          this.$refs["hero"].style.height = window.innerHeight + "px";
          this.$refs["newsDepth"].style.height = window.innerHeight + "px";
          this.$refs["hl04Depth"].style.height = window.innerHeight - 44 + "px";
          this.$refs["specialWallPc"].style.height = window.innerHeight + "px";
          this.$refs["specialWallSp"].style.height = window.innerHeight + "px";
          this.$refs["ticketWall"].style.height = window.innerHeight + "px";
          this.$refs["guideWall"].style.height = window.innerHeight + "px";
          this.$refs["digitalWall"].style.height = window.innerHeight + "px";
          this.$refs["projectWall"].style.height = window.innerHeight + "px";
          this.$refs["goodsWall"].style.height = window.innerHeight + "px";
        }

        let index = 0;
        for (const key in this.sectionList) {
          const rectLeft =
            this.$refs[key].getBoundingClientRect().left +
            this.scrollValue -
            window.innerWidth / this.transNum;
          const value =
            (this.$refs["indicatorBody"].clientWidth / this.area) * rectLeft;
          const pos =
            value - this.$refs["indicatorSection"][index].clientWidth / 2;
          this.$refs["indicatorSection"][index].style.left = pos + "px";
          this.$refs["indicatorSection"][index].dataset.value = pos;
          if (index == 0) {
            this.$refs["indicatorSection"][index].classList.add("is-on");
            this.$refs["indicatorSection"][index].style.left =
              -(this.$refs["indicatorSection"][index].clientWidth / 2) + "px";
            this.$refs["indicatorSection"][index].dataset.value = 0;
          } else if (index == this.section - 1) {
            this.$refs["indicatorSection"][index].style.left = "auto";
            this.$refs["indicatorSection"][index].style.right =
              -(this.$refs["indicatorSection"][index].clientWidth / 2) + "px";
            this.$refs["indicatorSection"][index].dataset.value =
              this.$refs["indicatorBody"].clientWidth;
          }
          index++;
        }

        this.area = sectionsWidth - window.innerWidth;
        const value =
          (this.$refs["indicatorBody"].clientWidth / this.area) *
          this.scrollValue;
        this.$refs["indicatorMain"].style.width = value + "px";

        imageMapResize();
      });

      const parallaxArray = document.querySelectorAll(".js-parallax");
      this.$refs["truck"].addEventListener("scroll", () => {
        this.Scroll(parallaxArray);
      });

      imageMapResize();
    });
  },
  methods: {
    Scroll(parallaxArray) {
      this.scrollValue = this.$refs["truck"].scrollLeft;
      this.Parameter();

      for (let index = 0; index < parallaxArray.length; index++) {
        const offset = window.pageXOffset;
        const left = parallaxArray[index].getBoundingClientRect().left;
        const width = window.innerWidth;
        const position = offset + left - width;
        const duration = 0.3;
        if (offset >= position) {
          new Promise((resolve) => {
            let x = (offset - position) * duration;
            parallaxArray[index].style.transform =
              "translate3d(-" + x + "px, 0, 0)";
            resolve();
          }).then(() => {
            if (parallaxArray[index].dataset.parallax !== "on") {
              parallaxArray[index].dataset.parallax = "on";
            }
          });
        }
      }
    },
    Wheel(e) {
      if (this.isStopScroll) return;
      this.$refs["truck"].scrollLeft += e.deltaX;
      this.$refs["truck"].scrollLeft += e.deltaY;
    },
    Parameter() {
      const value =
        (this.$refs["indicatorBody"].clientWidth / this.area) *
        this.scrollValue;
      this.$refs["indicatorMain"].style.width = value + "px";

      const goodsIndex = 8;
      const aboutIndex = 9;
      const aboutAdjust = 1;
      const aboutThreshold =
        this.$refs["indicatorSection"][aboutIndex].dataset.value - aboutAdjust;

      for (
        let index = 0;
        index < this.$refs["indicatorSection"].length;
        index++
      ) {
        const rectLeft = Number(
          this.$refs["indicatorSection"][index].dataset.value
        );
        const nextRectLeft = this.$refs["indicatorSection"][index + 1]
          ? Number(this.$refs["indicatorSection"][index + 1].dataset.value)
          : this.$refs["indicatorBody"].clientWidth;

        if (value >= rectLeft && value <= nextRectLeft) {
          this.$refs["indicatorSection"][index].classList.add("is-on");
        } else {
          this.$refs["indicatorSection"][index].classList.remove("is-on");
        }

        if (index === aboutIndex && value >= aboutThreshold) {
          this.$refs["indicatorSection"][goodsIndex].classList.remove("is-on");
          this.$refs["indicatorSection"][aboutIndex].classList.add("is-on");
        }
      }
    },
    Menu() {
      this.isMenu = !this.isMenu;
    },
    Anchor(id) {
      let pos;
      if (id == "summary") {
        pos =
          this.$refs[id].getBoundingClientRect().left +
          this.$refs["truck"].scrollLeft;
      } else {
        pos =
          this.$refs[id].getBoundingClientRect().left -
          window.innerWidth / this.transNum +
          this.$refs["truck"].scrollLeft;
      }
      this.$refs["truck"].scrollTo({
        top: 0,
        left: Number(pos),
        behavior: "smooth",
      });
    },
    CloseModal() {
      this.isClose = !this.isClose;
    },
    CloseAnchor() {
      this.isClose = false;
      this.Anchor("digital");
    },
    Modal(e) {
      this.isStopScroll = !this.isStopScroll;
      this.isModal = !this.isModal;
      if (this.isModal) {
        this.sliderImagesModal = JSON.parse(e.currentTarget.dataset.images);
        this.modalHeadingMain = e.currentTarget.dataset.headingMain;
        this.modalHeadingSub = e.currentTarget.dataset.headingSub;
        this.modalText = e.currentTarget.dataset.text;
      } else {
        setTimeout(() => {
          this.sliderImagesModal = 0;
          this.modalHeadingMain = "";
          this.modalHeadingSub = "";
          this.modalText = "";
        }, 300);
      }
    },
    QA() {
      this.isStopScroll = !this.isStopScroll;
      this.isQA = !this.isQA;
    },
    guideModal(e) {
      this.isStopScroll = !this.isStopScroll;
      this.isGuideModal = !this.isGuideModal;
      const contents = document.querySelector(
        ".guideModal ." + e.target.dataset.id
      );
      if (contents) {
        contents.classList.add("is-active");
      } else {
        const activeContents = document.querySelector(".guideModal .is-active");
        activeContents.classList.remove("is-active");
      }
    },
    onClickYoutube() {},
  },
};
</script>

<style lang="scss" scoped>
.home {
}

.alert {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  width: 100vw;
  height: 100vh;
  font-size: 2.1rem;
  line-height: 1.7;
  background-color: #002d6e;
  color: #fff;
  z-index: 9999;
  @include bp("pc") {
    display: none;
  }
  &.is-active {
    display: flex;
  }
}

.texture-overlay {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/content/files/assets/img/texture.png);
  pointer-events: none;
  mix-blend-mode: screen;
  opacity: 0.3;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
    height: 100%;
    max-height: 800px;
  }
  img {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    @include transition();
    @include bp("sp") {
      top: 10px;
      right: 10px;
      width: 168px;
      height: 48px;
    }
  }
}
.banner.is-active {
  img {
    pointer-events: auto;
    opacity: 1;
  }
}

.texture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/content/files/assets/img/texture.png);
  z-index: 99;
  pointer-events: none;
  mix-blend-mode: screen;
  opacity: 0.3;
}

.opening {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  z-index: 9999;

  .loader {
    position: absolute;
    top: calc(50% - 74px);
    left: calc(50% - 37px);
    margin: auto;
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background: #002d6e;
    background: linear-gradient(
      to right,
      #002d6e 10%,
      rgba(255, 255, 255, 0) 42%
    );
    animation: load3 1s infinite linear;
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #002d6e;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .loader:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.road {
  display: flex;
  align-items: center;
  margin: auto 0;
  width: 100%;
  height: 100vh;
  background-color: #002d6e;
}

.menu {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: -282px;
  padding-left: 16px;
  padding-bottom: 85px;
  width: 282px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  will-change: opacity, transform;
  transition: opacity 300ms, transform 150ms;
  &.is-active {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(282px, 0, 0);
  }
  &__shadow {
    @include transition(100);
    position: absolute;
    top: 0;
    left: 282px;
    mix-blend-mode: multiply;
    opacity: 0;
  }
  &.is-active &__shadow {
    @include transition(900);
    transition-delay: 200ms;
    opacity: 1;
  }
  &__wrap {
  }
  .sns {
    position: absolute;
    top: 26px;
    right: 20px;
    &__item {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-left: 0;
      }
    }
    &__link {
      @include hover();
    }
  }
  .list {
    margin-bottom: 16px;
    &__item {
      @include hover();
      @include fontZKGA("bold");
      margin-bottom: 14px;
      font-size: 1.8rem;
      color: #0054cd;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .out {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 0;
    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 24px;
      height: 1px;
      background-color: #0080c3;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    &__item {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__link {
      @include link();
      @include fontZKGA("bold");
      font-size: 1.3rem;
      color: #0080c3;
      p {
        display: inline-block;
        margin-left: -7px;
        margin-top: 3px;
      }
    }
  }
  .info {
    &__item {
      @include fontZKGA("bold");
      font-size: 0.9rem;
      line-height: 1.6;
      color: #0080c3;
    }
  }
}
_::-webkit-full-page-media,
_:future,
:root .menu .out__link p {
  margin-left: 0px;
}

.btn {
  position: fixed;
  bottom: 12px;
  left: 16px;
  width: 43px;
  height: 43px;
  z-index: 9999;
  outline: none;
  cursor: pointer;
  pointer-events: auto;
  @include bp("sp") {
    top: 12px;
    left: 12px;
    bottom: auto;
  }
  &__open {
    @include middle("xy");
    opacity: 1;
    @include transition(100);
  }
  &__close {
    @include middle("xy");
    opacity: 0;
    @include transition(100);
  }
  &.is-active &__open {
    opacity: 0;
  }
  &.is-active &__close {
    opacity: 1;
  }
}

.indicator {
  @include fontEn();
  position: fixed;
  bottom: 32px;
  left: 100px;
  width: calc(100% - 130px);
  z-index: 999;
  pointer-events: auto;
  @include bp("sp") {
    display: none;
  }
  &__body {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: 0.4;
  }
  &__main {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 1px;
    background-color: #fff;
  }
  .section {
    @include transition(300);
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    width: 8px;
    height: 8px;
    cursor: pointer;
    &::before {
      @include transition(300);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
    }
    &__link {
      @include middle("x");
      @include transition(300);
      white-space: nowrap;
      bottom: 18px;
      font-size: 16px;
      color: #fff;
      opacity: 0;
    }
  }
  .section:hover::before {
    transform: scale(2);
  }
  .section:hover .section__link {
    opacity: 1;
  }
  .is-on.section::before {
    transform: scale(2);
  }
  .is-on.section .section__link {
    opacity: 1;
  }
}

.closeModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  @include transition();
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__content {
    display: flex;
    padding: 40px;
    padding-right: 60px;
    width: 100%;
    max-width: 1000px;
    background-color: #ecebe3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    & > * {
      img,
      source {
        width: 100%;
        height: auto;
      }
    }
    @include bp("sp") {
      display: block;
      max-width: 303px;
      padding: 40px 38px 33px;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
    @include bp("sp") {
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
    }
  }
  &__autographs {
    margin-right: 62px;
    @include bp("sp") {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__message {
    flex-shrink: 0;
    flex-grow: 0;
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    @include fontZKGA("bold");
    font-size: 1.9rem;
    color: #0054ca;
    @include bp("sp") {
      width: 100%;
      font-size: 1.1rem;
    }

    img {
      width: 89.247311828%;
      margin-bottom: 17px;
      // margin-bottom: 21px;
      @include bp("sp") {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    a {
      position: relative;
      cursor: pointer;
      &::after {
        position: absolute;
        left: 0;
        bottom: 2px;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #0054ca;
        @include bp("sp") {
          bottom: 1px;
        }
      }
    }
    ul {
      width: 100%;
    }
    li {
      padding-left: 1.4em;
      position: relative;
      // margin-bottom: 28px;
      margin-bottom: 22px;
      letter-spacing: 0;
      line-height: 29px;
      @include bp("sp") {
        padding-left: 13px;
        letter-spacing: 0;
        line-height: 15px;
        margin-bottom: 6px;
      }
      &:before {
        content: "●";
        position: absolute;
        left: 0;
        // left: -2px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include bp("sp") {
      font-size: 1.1rem;
    }
  }

  &__ttl {
    font-size: 36px;
    width: 100%;
    text-align: center;
    @include bp("sp") {
      font-size: 20px;
    }
  }
  &__link {
    text-align: center;
    width: 100%;
    padding: 15px 0 23px;
    @include bp("sp") {
      padding: 10px 0 14px;
    }

    a {
      font-weight: bold;
      font-size: 16px;
      @include bp("sp") {
        font-size: 13px;
      }
    }
  }
  &__text {
    width: 100%;
    color: #456E8E;
    text-align: center;
    span {
      display: block;
      &.-t1 {
        font-size: 20px;
        @include bp("sp") {
          font-size: 12px;
        }
      }
      &.-t2 {
        font-size: 13px;
        line-height: 20px;
        @include bp("sp") {
          font-size: 10px;
          line-height: 13px;
        }
      }
      &.-t3 {
        font-size: 13px;
        line-height: 20px;
        @include bp("sp") {
          font-size: 10px;
          line-height: 13px;
        }
      }
      &.-t4 {
        font-size: 18px;
        margin-top: 20px;
        @include bp("sp") {
          font-size: 12px;
          margin-top: 12px;
        }
      }
      &.-t5 {
        font-size: 12px;
        margin-top: 20px;
        @include bp("sp") {
          font-size: 10px;
          margin-top: 12px;
        }
      }
      &.-t6 {
        font-size: 18px;
        margin-top: 20px;
        @include bp("sp") {
          font-size: 10px;
          margin-top: 12px;
        }
      }
    }
  }
}

.closeModal.is-active {
  opacity: 1;
  pointer-events: auto;
  .closeModal__close {
    pointer-events: auto;
  }
}

.modal {
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  @include transition(300);
  &.is-active {
    pointer-events: auto;
    opacity: 1;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  &__wrap {
    @include middle();
    display: flex;
    align-items: center;
    position: relative;
    padding: 48px 60px 36px;
    width: 100%;
    max-width: 1060px;
    background-color: #0080c3;
    @include bp("sp") {
      top: 0;
      left: 0;
      padding: 50px 16px 64px;
      transform: none;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    @include bp("sp") {
      top: 16px;
      right: 16px;
      width: 25px;
      height: 25px;
    }
    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 1px;
      height: 100%;
      background-color: #80aacc;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  &__contents {
    display: flex;
    align-items: center;
    @include bp("sp") {
      display: block;
    }
  }
  &__block {
    &:nth-child(1) {
      margin-right: 38px;
      @include bp("sp") {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
  &__block:nth-child(2) {
    width: 260px;
    @include bp("sp") {
      width: 100%;
    }
  }
  &__slider {
    width: 100%;
    max-width: 628px;
  }
  .heading {
    color: #d6d6d6;
    margin-bottom: 24px;
    @include bp("sp") {
      margin-bottom: 16px;
    }
    &__main {
      @include fontZKGA("bold");
      display: block;
      margin-bottom: 14px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d6d6d6;
      font-size: 3.2rem;
      @include bp("sp") {
        font-size: 2.2rem;
      }
    }
    &__sub {
      display: block;
      font-size: 2.2rem;
      @include bp("sp") {
        font-size: 1.6rem;
      }
    }
  }
  &__text {
    color: #d6d6d6;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.qa {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  @include transition();
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  &__wrap {
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 80%;
    max-width: 1000px;
    @include bp("sp") {
      height: 90%;
      max-width: 340px;
    }
  }
  &__cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 80%;
    max-width: 1000px;
    pointer-events: none;
    @include bp("sp") {
      height: 90%;
      max-width: 340px;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
    @include bp("sp") {
      width: 25px;
      height: 25px;
    }
  }
  &__image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.qa.is-active {
  opacity: 1;
  pointer-events: auto;
  .qa__close {
    pointer-events: auto;
  }
}

.guideModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  @include transition();
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  &__wrap {
    @include middle();
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    @include bp("sp") {
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 90%;
      transform: none;
    }
  }
  &__cover {
    @include bp("sp") {
      position: fixed;
      top: 0;
      left: 0;
      margin: auto;
      width: 100%;
      pointer-events: none;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 60px;
    cursor: pointer;
    pointer-events: none;
    @include bp("sp") {
      top: 50px;
      right: 40px;
      width: 25px;
      height: 25px;
    }
  }
  &__container {
    box-sizing: content-box;
    position: relative;
    padding: 0 40px;
    width: 100%;
    max-width: 1064px;
    @include bp("sp") {
      box-sizing: border-box;
      padding: 0 20px;
    }
  }

  .amb {
    display: none;
  }
  .amb.is-active {
    display: block;
  }

  .chara {
    display: none;
    .guideModal__contents {
      padding: 26px 46px;
      @include bp("sp") {
        padding: 40px 18px;
      }
    }
  }
  .chara.is-active {
    display: block;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 48px;
    width: 100%;
    height: 92px;
    background-color: #0054ca;
    color: #ffffff;
    @include bp("sp") {
      display: block;
      padding: 22px 16px;
      height: auto;
    }
  }
  &__heading {
    @include fontZKGA("black");
    font-size: 6.1rem;
    @include bp("sp") {
      margin-bottom: 8px;
      font-size: 3.2rem;
    }
  }
  &__text {
    @include fontZKGA("bold");
    margin-left: 28px;
    font-size: 1.3rem;
    line-height: 1.5;
    @include bp("sp") {
      margin-left: 0;
      font-size: 1.2rem;
    }
  }
  &__contents {
    display: flex;
    justify-content: center;
    padding: 50px 90px;
    background-color: #ecebe3;
    @include bp("sp") {
      display: block;
      padding: 28px 18px;
    }
  }
  &__block.-image {
    width: 100%;
    position: relative;
    &.-small {
      min-width: 223px;
    }
    @include bp("sp") {
      margin: 0 auto 24px;
    }
  }
  &__iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  &__block.-text {
    margin-left: 50px;
    color: #0054ca;
    @include bp("sp") {
      margin-left: 0;
    }
  }

  .thumb {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // pointer-events: none;
    // &:hover {
    //   display: none;
    // }
  }

  .profile {
    margin-bottom: 28px;
    @include bp("sp") {
      margin-bottom: 24px;
    }
    .heading {
      @include fontZKGA("bold");
      margin-bottom: 12px;
      @include bp("sp") {
        margin-bottom: 8px;
      }
      &__main,
      &__sub {
        display: inline-block;
      }
      &__main {
        font-size: 3rem;
        @include bp("sp") {
          font-size: 2.2rem;
        }
      }
      &__sub {
        margin-left: 8px;
        font-size: 2rem;
        @include bp("sp") {
          font-size: 1.4rem;
        }
      }
    }
    &__text {
      font-size: 1.3rem;
      line-height: 1.6;
      @include bp("sp") {
        font-size: 1.2rem;
      }
    }
  }
  .comment {
    &__heading {
      @include fontZKGA("black");
      margin-bottom: 12px;
      font-size: 2rem;
      @include bp("sp") {
        margin-bottom: 8px;
        font-size: 1.8rem;
      }
    }
    &__text {
      font-size: 1.3rem;
      line-height: 1.6;
      @include bp("sp") {
        font-size: 1.2rem;
      }
    }
  }

  .list {
    display: flex;
    @include bp("sp") {
      display: block;
    }
    &__item {
      margin-right: 25px;
      width: calc(25% - 25px / (4 * 3));
      @include bp("sp") {
        margin-right: 0;
        margin-bottom: 32px;
        width: 100%;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
      .-image {
        img {
          width: 100%;
        }
      }
    }
    .profile {
      margin-top: 8px;
      margin-bottom: 0;
      padding-top: 8px;
      border-top: solid #0054ca 1px;
      line-height: 1.5;
      &__heading {
        margin-bottom: 8px;
        color: #0073b2;
        font-size: 1.1rem;
      }
      &__product {
        margin-bottom: 10px;
        color: #0073b2;
        font-size: 1rem;
      }
      &__text {
        color: #0054ca;
        font-size: 1.1rem;
      }
    }
  }
}
.guideModal.is-active {
  opacity: 1;
  pointer-events: auto;
  .guideModal__close {
    pointer-events: auto;
  }
}

.truck {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  margin: auto 0;
  height: 100%;
  max-height: 800px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.top {
  display: flex;
  justify-content: flex-start;
  position: relative;

  &__heavens {
    position: absolute;
    top: 0;
    height: 8px;
    z-index: 99;
  }

  .heading {
    margin-right: 24px;
    margin-left: 45px;
    height: 100%;
    @include bp("sp") {
      margin-left: 32px;
    }
    picture {
      display: block;
      height: 100%;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  &__heading {
    margin-right: 30px;
    img {
      @include bp("sp") {
        width: 73px;
        height: 104px;
      }
    }
  }
  .hero {
    display: flex;
    justify-content: flex-start;
    position: relative;
    background-color: #0080c3;
    .parts {
      position: absolute;
      left: 100%;
      height: 100%;
      &__ceiling {
        display: none;
        position: absolute;
        top: -10px;
        left: 0px;
        z-index: 1;
        @include bp("sp") {
          display: block;
        }
        img {
          width: 13px;
        }
      }
      &__shadow {
        position: absolute;
        height: 100%;
        img {
          mix-blend-mode: multiply;
          height: 100%;
        }
      }
    }
    &__chara {
      position: absolute;
      right: -680px;
      bottom: 0;
      @include bp("sp") {
        right: -250px;
        width: 296px;
        height: 343px;
      }
    }
  }

  .read {
    display: flex;
    justify-content: flex-start;
    padding-left: 230px;
    padding-right: 120px;
    background-color: #d9d9d9;
    @include bp("sp") {
      padding-left: 110px;
    }
    &__text {
      padding: 80px 64px 94px 40px;
      width: 554px;
      height: 607px;
      background-color: #0054cd;
      font-size: 1.5rem;
      line-height: 1.6;
      color: #ffffff;
      @include bp("sp") {
        padding: 84px 90px 50px 34px;
        width: 430px;
        height: 516px;
        font-size: 1.2rem;
      }
      p {
        width: 100%;
      }
    }

    .movie {
      position: relative;
      margin: auto 60px auto 0;
      width: 579px;
      height: 346px;
      @include bp("sp") {
        width: 367px;
        height: 223px;
      }
      &__slider {
        width: 532px;
        position: absolute;
        right: 100px;
        z-index: 1;
        @include bp("sp") {
          right: 70px;
          width: 338px;
        }
      }
      &__frame {
        position: absolute;
        top: -8px;
        left: -60px;
        z-index: 1;
        @include bp("sp") {
          top: -5px;
          left: -46px;
        }
        img {
          @include bp("sp") {
            width: 349px;
            height: 201px;
          }
        }
      }
      &__shadow {
        position: absolute;
        top: 0px;
        right: 68px;
        @include bp("sp") {
          top: 10px;
          right: 43px;
        }
        img {
          mix-blend-mode: multiply;
          @include bp("sp") {
            width: 348px;
            height: 204px;
          }
        }
      }
      &__plumbing {
        position: absolute;
        top: 307px;
        right: 154px;
        @include bp("sp") {
          top: 183px;
          right: 160px;
        }
        img {
        }
      }
    }

    .mess {
      margin: auto 0;
      &__board {
        @include bp("sp") {
          margin-top: 10px;
        }
        img {
          @include bp("sp") {
            width: 175px;
            height: 237px;
          }
        }
      }
      .detail {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        .sns {
          &__link {
            @include hover();
            display: flex;
            align-items: center;
            pointer-events: auto;
          }
          img {
            &:first-child {
              margin-right: 2px;
              @include bp("sp") {
                margin-right: -2px;
                width: 32px;
                height: 32px;
              }
            }
            &:last-child {
              @include bp("sp") {
                width: 34px;
                height: 7px;
              }
            }
          }
        }
        &__warning {
          display: flex;
          img {
            @include bp("sp") {
              width: 25px;
              height: 25px;
            }
            &:first-child {
              margin-right: 8px;
              @include bp("sp") {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}

.news {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-left: -1px;
  background-color: #d9d9d9;
  &__heading {
  }
  .heading {
    position: relative;
    padding-right: 204px;
    @include bp("sp") {
      margin-top: 24px;
    }
    &__main {
      @include bp("sp") {
        width: 124px;
        height: 120px;
      }
    }
    &__sub {
      position: absolute;
      top: 124px;
      right: 84px;
      @include bp("sp") {
        top: 60px;
        right: 122px;
        width: 91px;
        height: 47px;
      }
    }
    &__read {
      position: absolute;
      top: 210px;
      right: -19px;
      font-size: 1.6rem;
      color: #0073b6;
      line-height: 1.5;
      z-index: 1;
      @include bp("sp") {
        top: 117px;
        right: 32px;
        font-size: 1.2rem;
      }
    }

    .sns {
      height: 20px;
      position: absolute;
      top: 268px;
      right: 100px;
      display: flex;
      align-items: center;
      @include bp("sp") {
        top: 162px;
        right: 112px;
        height: 17px;
      }
      &__link {
        @include hover();
        display: block;
        pointer-events: auto;
        margin-right: 10px;
        @include bp("sp") {
          margin-right: 8px;
        }

        &:nth-of-type(1) {
          img {
            width: 22px;
            height: 20px;
            @include bp("sp") {
              width: 19px;
              height: 17px;
            }
          }
        }
        &:nth-of-type(2) {
          img {
            width: 20px;
            height: 20px;
            @include bp("sp") {
              width: 17px;
              height: 17px;
            }
          }
        }
        &:nth-of-type(3) {
          img {
            width: 17px;
            height: 20px;
            @include bp("sp") {
              width: 14px;
              height: 17px;
            }
          }
        }
        &:nth-of-type(4) {
          img {
            width: 23px;
            height: 20px;
            @include bp("sp") {
              width: 20px;
              height: 17px;
            }
          }
        }
      }
    }
  }

  &__chara {
    position: absolute;
    bottom: 0;
    left: 490px;
    z-index: 1;
    @include bp("sp") {
      left: 150px;
      width: 265px;
      height: 313px;
    }
  }
  .news {
    position: relative;
    padding-left: 56px;
    border-top: 0;
    @include bp("sp") {
      padding-left: 14px;
    }
    &__depth {
      height: 100%;
      picture {
        display: block;
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
    .board {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: 701px;
      height: 566px;
      pointer-events: auto;
      @include bp("sp") {
        width: 551px;
        height: 446px;
      }
      &__frame {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        @include bp("sp") {
          width: 551px;
          height: 446px;
        }
      }
      &__shadow {
        position: absolute;
        bottom: -71px;
        pointer-events: none;
        @include bp("sp") {
          left: 50px;
          bottom: 0;
          width: 603px;
          height: 357px;
        }
      }
      .post {
        overflow: hidden;
        position: absolute;
        top: 96px;
        left: 16px;
        padding: 20px 20px 30px;
        background-color: #f0efe9;
        width: 354px;
        height: 380px;
        pointer-events: auto;
        @include bp("sp") {
          top: 74px;
          left: 12px;
          width: 280px;
          height: 296px;
        }
        &__wrap {
          overflow-y: scroll;
          width: 100%;
          height: 100%;
        }
        .article {
          margin-bottom: 20px;
          line-height: 1.5;
          &__time {
            font-size: 1.2rem;
            color: #0073b6;
          }
          &__text {
            font-size: 1.2rem;
          }
        }
        &__scroll {
          @include fontEn();
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          margin: 0 auto;
          padding: 0 20px;
          width: calc(100% - 40px);
          height: 30px;
          border-top: 1px solid #0073b6;
          background-color: #f0efe9;
          text-align: center;
          font-size: 1.2rem;
          color: #0073b6;
        }
      }
      .twitter__wrap {
        position: absolute;
        top: 17px;
        right: 25px;
        overflow-y: scroll;
        width: auto;
        height: 460px;
        @include bp("sp") {
          top: 13px;
          right: 15px;
          height: 358px;
        }
      }
    }
  }
}

.hl {
  display: flex;
  position: relative;
  background-color: #0080c3;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  .heading {
    position: relative;
    padding-right: 200px;
    @include bp("sp") {
      padding-right: 146px;
    }
    &__main {
      @include bp("sp") {
        margin-top: 24px;
        width: 141px;
        height: 120px;
      }
    }
    &__sub {
      position: absolute;
      top: 124px;
      right: -4px;
      @include bp("sp") {
        top: 92px;
        right: -10px;
        width: 178px;
        height: 54px;
      }
    }
    &__read {
      position: absolute;
      top: 217px;
      right: -9px;
      color: #d9d9d9;
      line-height: 1.5;
      @include bp("sp") {
        top: 157px;
        right: -38px;
        font-size: 1.2rem;
      }
    }
  }
  &__wall-shadow {
    position: absolute;
    top: 0;
    left: 482px;
    @include bp("sp") {
      left: 327px;
    }
  }
  .list {
    display: flex;
    align-items: flex-end;
    position: relative;
    &__item {
      position: relative;
      height: calc(240px + 58px);
      @include bp("sp") {
        height: calc(180px + 57px);
      }
    }
    &__heading {
      img {
      }
    }
    &__click {
      display: block;
      margin: 0 auto 16px;
      text-align: center;
      font-size: 2rem;
      color: #e1e264;
      animation: flash 2s ease-out infinite;
      @keyframes flash {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    &__image {
      cursor: pointer;
    }
    &__chara {
      position: absolute;
      bottom: 0;
      pointer-events: none;
      z-index: 1;
    }
    .item {
      &--01 {
        margin-right: 136px;
        width: 400px;
        @include bp("sp") {
          margin-right: 70px;
          width: 374px;
        }
        .list__image {
          bottom: 204px;
          @include bp("sp") {
            bottom: 123px;
          }
          img:nth-of-type(1) {
            padding-right: 8px;
          }
          img:nth-of-type(2) {
            @include bp("sp") {
              width: 201px;
              height: 248px;
            }
          }
        }
        .list__chara {
          left: 70px;
          @include bp("sp") {
            left: -100px;
            width: 190px;
            height: 267px;
          }
        }
      }
      &--02 {
        margin-right: 88px;
        width: 404px;
        @include bp("sp") {
          margin-right: 80px;
          width: 367px;
        }
        .list__image {
          bottom: 186px;
          @include bp("sp") {
            bottom: 113px;
          }
          img:nth-of-type(1) {
            padding-right: 12px;
          }
          img:nth-of-type(2) {
            @include bp("sp") {
              width: 224px;
              height: 268px;
            }
          }
        }
      }
      &--03 {
        margin-right: 48px;
        width: 83px;
        @include bp("sp") {
          margin-right: 50px;
        }
      }
      &--04 {
        margin-right: 85px;
        height: 100%;
        @include bp("sp") {
          margin-right: 72px;
        }
        .list__heading {
          position: absolute;
          top: -0px;
          left: -154px;
          z-index: 1;
          @include bp("sp") {
            top: 26px;
            left: -140px;
          }
          img {
            width: 125px;
            height: 130px;
          }
        }
        .depth {
          overflow: hidden;
          position: relative;
          height: 100%;
          cursor: pointer;
          &__image {
            position: relative;
            top: 44px;
            height: calc(100% - 44px);
          }
        }
        .list__chara {
          left: 340px;
          @include bp("sp") {
            left: 20px;
            width: 355px;
            height: 287px;
          }
        }
      }
      &--05 {
        margin-right: 77px;
        width: 360px;
        @include bp("sp") {
          margin-right: 80px;
          width: 347px;
        }
        .list__image {
          img:nth-of-type(1) {
            padding-right: 62px;
          }
          img:nth-of-type(2) {
            @include bp("sp") {
              width: 202px;
              height: 425px;
            }
          }
        }
        .list__chara {
          right: -460px;
          @include bp("sp") {
            right: -160px;
            width: 165px;
            height: 302px;
          }
        }
      }
      &--06 {
        margin-right: 77px;
        width: 476px;
        @include bp("sp") {
          margin-right: 80px;
          width: 442px;
        }
        .list__image {
          bottom: 183px;
          @include bp("sp") {
            bottom: 126px;
          }
          img:nth-of-type(1) {
            padding-right: 3px;
          }
          img:nth-of-type(2) {
            @include bp("sp") {
              width: 299px;
              height: 219px;
            }
          }
        }
      }
      &--07 {
        margin-right: 195px;
        width: 374px;
        @include bp("sp") {
          margin-right: 169px;
          width: 349px;
        }
        .list__image {
          bottom: 197px;
          @include bp("sp") {
            bottom: 129px;
          }
          img:nth-of-type(1) {
            padding-right: 8px;
          }
          img:nth-of-type(2) {
            @include bp("sp") {
              width: 207px;
              height: 211px;
            }
          }
        }
        .list__chara {
          right: -260px;
          @include bp("sp") {
            right: -190px;
            width: 191px;
            height: 323px;
          }
        }
      }
    }
    &__heading {
      position: absolute;
      top: 0;
      left: 0;
    }
    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &__wall {
    position: relative;
    width: 128px;
    height: 100%;
    background: #0080ba;
    @include bp("sp") {
      width: 80px;
    }
  }
}

.special {
  position: relative;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__wall {
    height: 100%;
    img {
      width: auto;
      height: 100%;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 47vh;
    margin: auto 0;
    height: 100%;
    @include bp("sp") {
      left: 70vh;
    }
  }
  .list {
    display: flex;
    justify-content: flex-start;
    &__item {
      margin-right: 26px;
      max-width: 240px;
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 100%;
    }
  }
}

.ticket {
  position: relative;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__chara {
    position: absolute;
    left: 250px;
    bottom: 0;
    z-index: 1;
    @include bp("sp") {
      left: 0px;
      width: 236px;
      height: 379px;
    }
  }
  &__wall {
    height: 100%;
    img {
      height: 100%;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 120px;
    margin: auto;
    img {
      height: 73.142857142857143vh;
      max-height: 512px;
    }
  }
  map {
    area {
      cursor: pointer;
    }
  }
}

.guide {
  position: relative;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__wall {
    height: 100%;
    img {
      height: 100%;
    }
  }
  map {
    area {
      cursor: pointer;
    }
  }
}

.digital {
  position: relative;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__wall {
    height: 100%;
    img {
      height: 100%;
    }
  }
  &__caption {
    color: #0054ca;
    font-size: 1.2rem;
    height: 1em;
    width: 15em;
    position: absolute;
    top: 570px;
    left: -482px;
    right: 0;
    bottom: 0;
    margin: auto;
    @include bp("sp") {
      top: 336px;
      left: -236px;
    }
  }
  &__btn {
    position: absolute;
    top: 570px;
    right: 0;
    left: 450px;
    bottom: 0;
    margin: auto;
    width: 125px;
    height: 14px;
    @include bp("sp") {
      top: 336px;
      left: 290px;
    }
  }
  .movie {
    position: absolute;
    top: 204px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 579px;
    height: 346px;
    @include bp("sp") {
      top: 94px;
      width: 367px;
      height: 223px;
    }
    &__slider {
      width: 532px;
      position: absolute;
      right: 100px;
      z-index: 1;
      @include bp("sp") {
        right: 70px;
        width: 338px;
      }
    }
    &__frame {
      position: absolute;
      top: -8px;
      left: -60px;
      z-index: 1;
      pointer-events: none;
      @include bp("sp") {
        top: -5px;
        left: -46px;
      }
      img {
        @include bp("sp") {
          width: 349px;
          height: 201px;
        }
      }
    }
    &__youtube {
      width: 530px;
      position: absolute;
      top: 0px;
      left: -52px;
      @include bp("sp") {
        top: 0px;
        left: -42px;
        width: 340px;
        height: 190px;
      }
    }
    &__shadow {
      position: absolute;
      top: 0px;
      right: 68px;
      @include bp("sp") {
        top: 10px;
        right: 43px;
      }
      img {
        mix-blend-mode: multiply;
        @include bp("sp") {
          width: 348px;
          height: 204px;
        }
      }
    }
    &__plumbing {
      position: absolute;
      top: 307px;
      right: 154px;
      @include bp("sp") {
        top: 183px;
        right: 160px;
      }
      img {
      }
    }
  }
}

.project {
  position: relative;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__wall {
    height: 100%;
    img {
      height: 100%;
    }
  }
  &__board {
    position: absolute;
    right: 3.64741641337386%;
    bottom: 0;
    img {
      @include bp("sp") {
        width: 720px;
      }
    }
  }
  map {
    area {
      cursor: pointer;
    }
  }
}

.goods {
  position: relative;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__chara {
    position: absolute;
    left: 100px;
    bottom: 0;
    z-index: 1;
    @include bp("sp") {
      left: -10px;
      width: 205px;
      height: 373px;
    }
  }
  &__wall {
    height: 100%;
    img {
      height: 100%;
    }
  }
  &__link {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -75%);
  }
}

.summary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: relative;
  padding: 0 135px 0 148px;
  background-color: #0080c3;
  @include bp("sp") {
    padding: 0 100px 0 90px;
    border-top: solid 8px #464544;
  }
  &__shadow {
    position: absolute;
    top: 0;
    left: -1px;
    mix-blend-mode: multiply;
    pointer-events: none;
  }
  &__heading {
    position: relative;
    margin-bottom: 24px;
    @include bp("sp") {
      margin-bottom: 14px;
      width: 308px;
    }
  }
  dl {
    color: #fff;
    line-height: 1.5;
    div.block {
      margin-bottom: 8px;
      @include bp("sp") {
        margin-bottom: 4px;
      }
    }
    div.td {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
    }
    dt {
      @include fontZKGA("bold");
      white-space: nowrap;
      font-size: 1.6rem;
      @include bp("sp") {
        font-size: 1.4rem;
      }
    }
    dd {
      @include fontZKGA("bold");
      white-space: nowrap;
      font-size: 1.6rem;
      @include bp("sp") {
        font-size: 1.4rem;
      }
      span {
        display: inline-block;
        margin-left: 4px;
      }
    }
    .-small {
      font-size: 1.2rem;
      font-weight: normal;
      @include bp("sp") {
        font-size: 1rem;
      }
      dt {
        font-size: 1.2rem;
        font-weight: normal;
        @include bp("sp") {
          font-size: 1rem;
        }
      }
      dd {
        font-size: 1.2rem;
        font-weight: normal;
        @include bp("sp") {
          font-size: 1rem;
        }
      }
    }
    .acs {
      .td {
        display: block;
        iframe {
          display: block;
          margin: 8px 0;
          filter: grayscale(100%);
          @include bp("sp") {
            width: 309px;
            height: 98px;
          }
        }
      }
    }
    .note {
      font-size: 1rem;
    }
    .shadow {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .decorate {
      @include fontZKGA("bold");
      margin-left: 1.25px;
      letter-spacing: -0.05em;
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: #fff;
      }
    }
    .contact {
      line-height: 1.3;
    }
  }
}

.exit {
  position: relative;
  padding-right: 104px;
  background-color: #002d6e;
  @include bp("sp") {
    border-top: solid 8px #464544;
  }
  &__wall {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  &__board {
    position: absolute;
    bottom: 0;
    left: -69px;
  }
}
</style>
