<template lang="pug">
.youtube
	youtube.youtube__iframe(:video-id="videoId" :width="width" :height="height" :resize="resize" :fitParent="fitParent" :player-vars="playerVars" ref="youtube")
	img.youtube__thumb(:src="`/content/files/assets/img/${thumbnail}`" :alt="alternative" @click="playVideo" ref="thumbnail")
</template>

<script>
import Vue from "vue";
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);

const VIDEOS = [
  {
    id: "kRj5_0A8Ims",
    src: "guide-modal_img_amb.jpg",
    alt: "川島 明",
    width: 356,
    height: 200,
  },
  {
    id: "4A-hTNxCm0o",
    src: "guide-modal_list_chara_thumb_01.jpg",
    alt: "矢口八虎 峯田大夢 （スターダストプロモーション所属）",
    width: 223,
    height: 125,
  },
  {
    id: "2jO6NHZ80nY",
    src: "guide-modal_list_chara_thumb_02.jpg",
    alt: "高橋世田介 山下大輝 （アーツビジョン所属）",
    width: 223,
    height: 125,
  },
  {
    id: "qqdRnmvo7HI",
    src: "guide-modal_list_chara_thumb_03.jpg",
    alt: "橋田悠 河西健吾 （マウスプロモーション所属）",
    width: 223,
    height: 125,
  },
  {
    id: "D-ITZSOnkOQ",
    src: "guide-modal_list_chara_thumb_04.jpg",
    alt: "森まる 青耶木まゆ （フクダ＆Co.所属）",
    width: 223,
    height: 125,
  },
  {
    id: "YSGWR8yad5Q",
    src: "slider_digital_img_01.jpg",
    alt: "ブルーピリオド展デジタルダイジェスト",
    width: 340,
    height: 191,
  },
];

export default {
  props: {
    index: Number,
    open: Boolean,
  },
  data() {
    return {
      videoId: VIDEOS[this.index].id,
      thumbnail: VIDEOS[this.index].src,
      alternative: VIDEOS[this.index].alt,
      width: VIDEOS[this.index].width,
      height: VIDEOS[this.index].height,
      playing: false,
      resize: false,
      fitParent: false,
      playerVars: {
        rel: 0,
        loop: 1,
        playlist: VIDEOS[this.index].id,
      },
    };
  },
  methods: {
    playVideo() {
      this.player.playVideo();
      this.$refs["thumbnail"].style.display = "none";
      this.playing = true;
      this.resize = true;
      this.fitParent = true;
    },
    pauseVideo() {
      this.$emit();
      this.player.pauseVideo();
      this.playing = false;
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    open() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style scoped lang="scss">
.youtube {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  cursor: pointer;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__thumb {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
